import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserAuthService } from '../../services/auth-service/user-auth.service';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LoadingScreenComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  isAuth!: boolean;
  deviceOS!: string;
  loading: boolean = false;
  isValidToken!: boolean;
  appleStoreURL: string = 'https://apps.apple.com/app/%D9%84%D9%8A%D8%B2%D8%B1-lazer/id6478968924';
  googleStoreURL: string = 'https://play.google.com/store/apps/details?id=sa.laser';

  constructor(
    private _UserAuthService: UserAuthService,
    private _ToastrService: ToastrService,
    private _DeviceDetectorService: DeviceDetectorService,
    private _Router: Router
  ) { }

  ngOnInit(): void {
    this._UserAuthService.authenticated.subscribe(value => this.isAuth = value);
    this.deviceOS = this._DeviceDetectorService.getDeviceInfo().os;
    this.isAuthFn();
  }

  isAuthFn() {
    if (localStorage.getItem('uToken') !== null) {
      this._UserAuthService.updateAuthFn(true);
    }
  }

  // validateToken() {
  //   this.loading = true;
  //   let isValid: any = undefined;
  //   this._UserAuthService.tokenValidator({}).subscribe({
  //     next: (response) => {
  //       if (response.status === true && response.error === false) this.isValidToken = true;
  //     },
  //     error: (error) => {
  //       this.loading = false;
  //       if (error.status === 401 && error.error.message === 'Unauthenticated.') this.isValidToken = false
  //     },
  //     complete: () => {
  //       this.loading = false;
  //     },
  //   })
  // }

  logout() {
    // this.validateToken()
    this.loading = true;
    this._UserAuthService.logout().subscribe({
      next: (response) => {
        if (response.status === true && response.error === false) {
          localStorage.clear();
          this._UserAuthService.updateAuthFn(false);
          this._ToastrService.success('تم تسجيل الخروج بنجاح', 'نـجـاح')
          this._Router.navigate(['/'])
        }
      },
      error: (error) => {
        this.loading = false;
        if (error.status === 401 && error.error.message === 'Unauthenticated.') {
          localStorage.clear();
          this._UserAuthService.updateAuthFn(false);
          this._ToastrService.success('تم تسجيل الخروج بنجاح', 'نـجـاح')
          this._Router.navigate(['/'])
        }
      },
      complete: () => {
        this.loading = false;
      },
    })
  }

  storeLink(): void {
    switch (this.deviceOS) {
      case 'Mac OS':
        window.open(this.appleStoreURL, '_blank');
        break;
      case 'iOS':
        window.open(this.appleStoreURL, '_blank');
        break;
      default:
        window.open(this.googleStoreURL, '_blank');
        break;
    }
  }
}
