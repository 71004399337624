<!-- privacy-section-1 -->
<section class="privacy-section-1">
    <div class="container mx-auto pt-8 pb-4 px-8">
        <!-- arabic-section -->
        <div>
            <h2 class="text-2xl font-bold">شروط واحكام تطبيق ليزر</h2>
            <p class="text-lg font-bold my-1">المقدمة</p>
            <article>
                مرحبًا بكم في تطبيق ليزر، يقدم التطبيق خدمات صيانة عالية الجودة مباشرة إلى منزلك، حيث يعمل فريقنا
                المتخصص على إصلاح مشكلات أجهزتك الذكية بكفاءة وسرعة. من خلال تطبيق ليزر، يمكنك طلب الصيانة بسهولة لأي من
                أجهزتك الذكية مثل الجوالات، الايباد، الساعات الذكية، وأجهزة اللابتوب، مع متابعة طلبك بخطوات بسيطة وسهلة.
            </article>

            <!-- 1 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">تفاصيل الطلب والأسعار : طلب صيانة مع تطبيق ليزر بسيط للغاية:</p>
                <ol class="list-decimal ps-8">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            التسجيل
                        </span>
                        بعد التسجيل، قم بتقديم معلومات مفصلة عن جهازك.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            اختيار المشكلة :
                        </span>
                        اختر من الفئات المعروضه الفئة المناسبة مع مشكلتك أو اختر "أخرى" إذا كنت غير متأكد من المشكلة
                        الموجودة بجهازك.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            دفع رسوم التأكيد/الفحص :
                        </span>
                        يلزم دفع رسوم تأكيد بقيمة 50 ريال لتأكيد موعدك وفحص الجهاز وتأكيد المشكلة المقدمة من قبلكم. سيتم
                        خصم هذه الرسوم من إجمالي تكلفة الصيانة في حالة اتمام الصيانة معنا و بعد فحص الفني و تأكيد
                        المشكلة.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            زيارة الفني:
                        </span>
                        يتم دفع باقي مبلغ الصيانة بعد قيام الفني بتقييم وتأكيد المشكلة المطروحة.
                    </li>
                </ol>
            </div>

            <!-- sub-1 -->
            <h2 class="text-2xl font-bold">نطاق الاسعار والخدمات المقدمة</h2>
            <p class="text-lg font-bold my-1">فئات الصيانة في تطبيق ليزر</p>
            <article>
                في تطبيق ليزر، نقدم فئات عامة للمشاكل الشائعة للأجهزة الذكية مثل مشاكل الشاشة، الشحن، الصوت، التصوير،
                جسم الجهاز، وغيرها. هذه الفئات تهدف لمساعدتك على اختيار الفئة الأنسب المرتبطة بمشكلة جهازك. كل فئة تشمل
                مجموعة من الخدمات المتعلقة بها، لذا نوصي باختيار الفئة العامة التي تعكس طبيعة المشكلة التي تواجهها في
                جهازك.
            </article>

            <!-- sub-1 -->
            <p class="text-lg font-bold my-1">الحد الأدنى والحد الأقصى للتكلفة</p>
            <article>
                لمساعدتك في فهم التكاليف المحتملة بشكل أفضل، نقوم بعرض مبلغ يتراوح بين حد أدنى وحد أقصى لكل فئة من فئات
                المشاكل المعروضة. يعكس هذا النطاق التقديري التكاليف المحتملة المتعلقة بالخدمات المختلفة التي قد تحتاجها
                ضمن تلك الفئة. ويأتي هذا التقدير لضمان حصولك على فكرة عامة عن التكلفة المحتملة قبل تأكيد المشكلة الفعلية
                من قبل الفني.
            </article>

            <!-- 2 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">لماذا يتم تقديم هذا النطاق</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            تغطية شاملة للخدمات :
                        </span>
                        يشمل هذا النطاق مجموعة الإصلاحات والأجزاء التي قد تكون ضرورية و مرتبطة بمشكلتك على سبيل المثال،
                        قد تكون تكلفة استبدال البطارية معروفة ومحددة، لكن اذا كان الجهاز يعاني من مشكلة في الشحن نفسه و
                        ليس البطارية فقد يتطلب ذلك إجراءات وتكاليف إضافية.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            تقييم الفني :
                        </span>
                        سيتم تحديد التكلفة الدقيقة بعد تقييم الفني للجهاز وتأكيد المشكلة المحددة. السعر الأولي المقدم
                        يساعد في تحديد التوقعات، ولكن قد يتم تعديله بناءً على الحالة الفعلية والإصلاحات المطلوبة.
                    </li>
                </ul>
            </div>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">تكاليف الصيانة المتقدمة</p>
            <article class="mb-4">
                تختلف تكاليف الصيانة المتقدمة بناءً على المشكلة المحددة التي يتم اكتشافها خلال الفحص. لا يمكن تحديد
                التكلفة النهائية للصيانة المتقدمة إلا بعد أن يقوم الفني بفحص الجهاز وتحديد المشكلة بدقة.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">المسؤولية</p>
            <article class="mb-4">
                يتحمل العملاء مسؤولية تقديم تفاصيل دقيقة حول جهازهم والمشكلة الموجودة. يلتزم فنيون ليزر بمعالجة المشكلة
                المحددة التي أبلغ عنها العميل وأكدها الفني. إذا كانت هناك مشكلات إضافية يتم اضافتها على تكلفة الخدمة
                الاساسية و اذا كانت معلومات الجهاز المقدمة من قبل العميل غير صحيحة، فيلزم هنا تقديم طلب صيانة جديد مع
                دفع رسوم تأكيد جديدة.
                <br>
                إذا لم يكن العميل متأكدًا من المشكلة، فلا داعي للقلق. تغطي رسوم الفحص تقييم الفني للمشكلة. سيقوم الفني
                بتشخيص المشكلة وإبلاغ العميل بها. إذا كانت الأجزاء المطلوبة للصيانة متوفرة مع الفني، سيتم تنفيذ الصيانة
                بعد الحصول على تأكيد العميل. وإذا لم تكن متوفرة، سيتم تحديد موعد جديد لضمان توافر قطع الغيار اللازمة
                للصيانة.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">مدة الصيانة</p>
            <article class="mb-4">
                بينما يمكن حل العديد من المشكلات داخل سيارة الصيانة عند موقع العميل المحدد من قبل، قد تتطلب بعض المشكلات
                صيانة متقدمة في ورشتنا الخاصة. و يمكن تحديد ذلك فقط بعد الفحص الأولي للجهاز.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">الدفع</p>
            <article class="mb-4">
                يمكن إجراء عملية الدفع عبر Apple Pay وبطاقات الائتمان من خلال التطبيق أو باستخدام رصيد المحفظة إذا كان
                متاحًا. يتم الدفع المتبقي بعد تأكيد الفني للمشكلة. جميع المعاملات تتم بالريال السعودي وتشمل ضريبة القيمة
                المضافة بنسبة 15%.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">جودة قطع الغيار</p>
            <article class="mb-4">
                جميع قطع الغيار المستخدمة في الصيانة إما أصلية أو من الدرجة الأولى عالية الجودة، حسب الجهاز والمشكلة.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">الضمان</p>
            <article class="mb-4">
                يختلف الضمان المقدم حسب الخدمة المقدمة وقطع الغيار المستخدمة. سيتم توفير تفاصيل الضمان عند تقديم الخدمة.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">بعد الصيانة</p>
            <article class="mb-4">
                بعد إتمام خدمة الصيانة، يُطلب من العميل التحقق من أن الجهاز يعمل بشكل صحيح. بمجرد التأكيد، سنقدم ضمانًا
                لمدة 72 ساعة على الخدمة المنفذة. هذا يضمن راحة البال بأن أي مشكلات تتعلق بالخدمة المقدمة سيتم معالجتها
                ضمن فترة الضمان.
            </article>

            <!-- 3 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">إلغاء الطلب</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            قبل ساعتين :
                        </span>
                        إذا كنت ترغب في إلغاء طلبك قبل ساعتين من موعد وصول الفني المحدد، سيتم رد رسوم التأكيد او الفحص
                        إلى محفظتك. إذا كنت تفضل استرداد المبلغ إلى حسابك البنكي، يرجى التواصل مع خدمة العملاء لدينا.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            بعد وصول الفني :
                        </span>
                        إذا وصل الفني وكنت غير متجاوب أو غير متاح، فلن يتم استرداد رسوم التأكيد او الفحص.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            بعد تأكيد المشكلة:
                        </span>
                        إذا قام الفني بفحص الجهاز وتأكيد المشكلة والخدمة المطلوبة ولكنك اخترت عدم المتابعة في الصيانة،
                        فلن يتم استرداد رسوم التأكيد او الفحص.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            في حالة تأجيل الموعد:
                        </span>
                        يمكن للعميل تأجيل الموعد قبل الموعد المحدد بساعتين كيفما يشاء ولا يمكن استرجاع قيمة تأكيد الطلب
                        / الفحص في حالة طلب الغاء الموعد بعد تأجيله
                    </li>
                </ul>
            </div>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">أيام وساعات العمل</p>
            <article class="mb-4">
                أيام العمل من السبت إلى الخميس، وقد تكون الجمعة يوم إجازة. ساعات العمل محددة من الساعة 10:00 صباحًا إلى
                10:00 مساءً.
            </article>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">النطاق الجغرافي</p>
            <article class="mb-4">
                حاليًا، تقدم ليزر خدماتها في جدة، المملكة العربية السعودية، بناءً على توفر مقدمي الخدمة لدينا. نعتذر عن
                أي إزعاج إذا لم نتمكن من تقديم الخدمة في بعض المناطق.
            </article>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">العلامات التجارية</p>
            <article class="mb-4">
                جميع العلامات التجارية والشعارات والتصميمات المعروضة على تطبيق ليزر هي ملك لشركة ليزر أو أصحابها
                المعنيين. يحظر استخدام هذه العلامات التجارية بدون إذن ويخضع للحماية بموجب قوانين الملكية الفكرية.
            </article>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">معلومات الاتصال</p>
            <p class="text-lg font-bold my-1">التواصل مع خدمة العملاء</p>
            <article class="mb-4">
                يجب أن يتم أي تواصل يتعلق بالتأكيدات، أو الإلغاءات، أو الضمانات، أو الشكاوى، أو أي تفاصيل أخرى عبر خدمة
                العملاء فقط. مسؤولية الفني تقتصر على أداء عملية الصيانة، ولا يتعامل مع المسائل المتعلقة بالإلغاءات، أو
                التأكيدات، أو الضمانات، أو الشكاوى.
            </article>


            <div class="my-8">
                <p class="text-lg font-bold my-1">
                    شكرًا لاختيارك تطبيق ليزر. إذا كانت لديك أي تعليقات أو اقتراحات، يرجى التواصل معنا عبر
                </p>

                <ul class="list-disc ps-6">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            البريد الالكتروني:
                        </span>
                        info&#64;squarement.sa
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الهاتف:
                        </span>
                        <!-- +966 509 152 104 -->
                        104 152 509 966+
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            العنوان:
                        </span>
                        جدة، حي الرحاب، مركز األقصى لألعمال، المملكة العربية السعودية
                    </li>
                </ul>

            </div>

            <article>
                ليزر مملوكة لشركة Squarement، المسجلة في المملكة العربية السعودية تحت رقم السجل التجاري 4030414085.
                تحتفظ الشركة بجميع حقوقها الملكية والقانونية
            </article>

        </div>
    </div>

    <div class="w-3/4 mx-auto my-8">
        <hr>
    </div>

    <div class="container mx-auto pt-8 pb-4 px-8" style="direction: ltr;">
        <!-- arabic-section -->
        <div>
            <h2 class="text-2xl font-bold">Laser App Terms & Conditions</h2>
            <p class="text-lg font-bold my-1">Introduction</p>
            <article>
                Welcome to Laser App, your trusted mobile service for smart device repairs. We specialize in providing
                convenient, high-quality maintenance services right at your doorstep. Our skilled technicians are
                committed to resolving your device issues efficiently, ensuring your gadgets remain in optimal
                condition. Through our app, you can easily request repairs for smartphones, tablets, smartwatches, and
                laptops, track repair progress, and enjoy a seamless service experience.
            </article>

            <!-- 1 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">Order and Pricing Details: Initiating a maintenance request with Laser
                    App is straightforward:</p>
                <ol class="list-decimal ps-8">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Registration
                        </span>
                        After registering, provide detailed information about your device.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Issue Selection :
                        </span>
                        Choose the category of the problem from our list or select "Other" if you're unsure of the
                        issue.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Confirmation Fee/Checking Payment :
                        </span>
                        A confirmation fee of 50 SAR is required to secure your appointment and for checking the device
                        and confirm the communicated issue. This fee will be deducted from the total repair cost.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Technician Visit:
                        </span>
                        The remaining payment is due after the technician assesses and confirms the issue.
                    </li>
                </ol>
            </div>

            <!-- sub-1 -->
            <h2 class="text-2xl font-bold">Service Amount Range and Issue Categories</h2>
            <p class="text-lg font-bold my-1">Issue Categories on the Laser App</p>
            <article>
                In the Laser App, we offer general categories for common issues with smart devices, such as screen
                problems, charging issues, sound, camera, device body, and others. These categories are designed to help
                you choose the most suitable one related to your device's problem. Each category includes a range of
                associated services, so we recommend selecting the general category that best reflects the issue you're
                experiencing with your device.
            </article>

            <!-- sub-1 -->
            <p class="text-lg font-bold my-1">Minimum and Maximum Amounts</p>
            <article>
                In order to help you better understand the potential costs, we display a range with a minimum and
                maximum amount for each category of issues. This estimated range reflects the possible costs associated
                with various services within that category. This estimate is provided to give you a general idea of the
                potential expenses before the actual problem is confirmed by the technician.
            </article>

            <!-- 2 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">Why This Range is Provided</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Comprehensive Service Coverage :
                        </span>
                        The range accounts for the different types of repairs and parts that might be needed. For
                        instance, while a battery replacement may have a fixed cost, diagnosing and repairing a charging
                        circuit issue could involve additional steps and costs.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Technician Assessment :
                        </span>
                        The exact cost will become clear after the technician assesses the device and confirms the
                        specific problem. The initial range provided helps set expectations but may be adjusted based on
                        the actual condition and required repairs.
                    </li>
                </ul>
            </div>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Costs of Advanced Maintenance</p>
            <article class="mb-4">
                The cost of advanced maintenance varies based on the specific problem identified during the inspection.
                The final cost for advanced maintenance can only be determined after the technician has inspected the
                device and accurately identified the issue.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Liability</p>
            <article class="mb-4">
                Customers are responsible for providing accurate details about their device and the issue at hand. Laser
                is committed to addressing the specific problem reported by the customer and confirmed by our
                technician. If additional issues are identified, they will be added to the basic service cost. If the
                device information provided by the customer is incorrect, a new maintenance request must be submitted
                along with a new confirmation fee.
                <br>
                If the customer is unsure of the problem, there’s no need to worry. The checking fee covers the
                technician’s assessment of the issue. The technician will diagnose the problem and inform the customer.
                If the necessary parts for the repair are available with the technician, the repair will proceed after
                obtaining the customer’s confirmation. If not, a new appointment will be scheduled to ensure the
                required spare parts are available for the repair.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Repair Duration</p>
            <article class="mb-4">
                While many issues can be resolved on-site, some problems may necessitate advanced maintenance at our
                shop. This determination can only be made after an initial inspection of the device.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Payment</p>
            <article class="mb-4">
                Payments can be made via Apple Pay and credit card through the app or by using your wallet balance if
                available. The remaining payment is processed after the technician confirms the issue. All transactions
                are conducted in Saudi Riyals and include a 15% VAT.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Spare Parts Quality</p>
            <article class="mb-4">
                All spare parts used in repairs are either original or first-grade, depending on the device and issue.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Warranty</p>
            <article class="mb-4">
                The warranty provided varies based on the service performed and the spare parts used. Detailed warranty
                terms will be provided at the time of service.
            </article>

            <!-- sub-2 -->
            <p class="text-lg font-bold my-1">Post-Repair</p>
            <article class="mb-4">
                After completing the repair service, the customer is requested to check that the device is functioning
                properly. Once confirmed, we will provide a 72-hour warranty on the service performed. This ensures
                peace of mind that any issues related to the provided service will be addressed within this warranty
                period.
            </article>

            <!-- 3 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">Order Cancellation</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Before 2 Hours:
                        </span>
                        If you wish to cancel your order more than 2 hours before the technician’s scheduled arrival,
                        the confirmation fee will be refunded to your wallet. If you prefer a refund to your bank
                        account, please contact our customer service.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            After Technician Arrival :
                        </span>
                        If the technician arrives and you are unresponsive or unavailable, the confirmation fee will not
                        be refunded.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            • Post-Confirmation:
                        </span>
                        If the technician finalizes checking the device and confirms the issue and the needed service
                        but you choose not to proceed with the repair, the confirmation fee will not be refunded.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            In the case of rescheduling:
                        </span>
                        The customer can reschedule the appointment up to two hours before the scheduled time without
                        any issue. However, if the appointment is canceled after being rescheduled, the confirmation fee
                        will not be refunded.
                    </li>
                </ul>
            </div>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">Working Days & Hours</p>
            <article class="mb-4">
                Our working days are from Saturday to Thursday, with Fridays possibly off. Our working hours are from
                10:00 AM to 10:00 PM.
            </article>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">Geographical Scope</p>
            <article class="mb-4">
                Laser currently provides services in Jeddah, Saudi Arabia, based on the availability of our service
                providers. We apologize for any inconvenience if we are unable to offer service in certain areas.
            </article>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">Trademarks</p>
            <article class="mb-4">
                All trademarks, logos, and designs displayed on the Laser App are the property of Laser or their
                respective owners. Unauthorized use of these trademarks is prohibited and protected under intellectual
                property laws.
            </article>

            <!-- sub-3 -->
            <p class="text-lg font-bold my-1">Communication with Customer Service</p>
            <article class="mb-4">
                Any communication regarding confirmations, cancellations, warranties, complaints, or other details must
                be conducted exclusively through customer service. The technician’s responsibility is solely to perform
                the repair, and they do not handle matters related to cancellations, confirmations, warranties, or
                complaints.
            </article>


            <div class="my-8">
                <p class="text-lg font-bold my-1">
                    Contact Information:
                </p>
                <p class="text-lg font-bold my-1">
                    Thank you for choosing Laser. If you have any comments or suggestions, please contact us at:
                </p>

                <ul class="list-disc ps-6">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Email:
                        </span>
                        info&#64;squarement.sa
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Phone:
                        </span>
                        +966 509 152 104
                        <!-- 104 152 509 966+ -->
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            Office Address:
                        </span>
                        Jeddah, Al Rehab, Al Aqsa Business Center, Saudi Arabia
                    </li>
                </ul>

            </div>

            <article>
                Laser is owned by Squarement Company, registered in the Kingdom of Saudi Arabia under registration
                number 4030414085.
                <span class="font-bold">
                    The company retains all its intellectual and legal rights
                </span>.
            </article>

        </div>
    </div>
</section>