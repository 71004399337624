import { HttpInterceptorFn } from '@angular/common/http';

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const _token = localStorage.getItem('uToken');
  
  if (_token !== null) {
    const clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${_token}`,
      }
    })
    return next(clonedReq);
  } else {
    return next(req);
  }

};
