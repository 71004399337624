<!-- privacy-section-1 -->
<section class="privacy-section-1">
    <div class="container mx-auto pt-8 pb-4 px-8">
        <!-- arabic-section -->
        <div>
            <h2 class="text-2xl font-bold">سياسة الخصوصية</h2>
            <p class="text-lg font-bold my-1">المقدمة</p>
            <article>
                مرحبًا بكم في تطبيق ليزر. توضح سياسة الخصوصية هذه كيفية جمع واستخدام وحماية بياناتك الشخصية عند استخدامك
                لتطبيقنا.
                باستخدام تطبيق ليزر، فإنك توافق على جمع واستخدام معلوماتك كما هو موضح في هذه السياسة.
            </article>

            <!-- 1 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">جمع البيانات واستخدامها</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            جمع البيانات الشخصية:
                        </span>
                        نقوم بجمع المعلومات الشخصية التي تقدمها عند التسجيل أو عند تقديم الطلبات من خالل التطبيق. قد
                        تشمل هذه المعلومات اسمك، ورقم هاتفك، وموقعك، وتفاصيل جهازك.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            بيانات االستخدام :
                        </span>
                        نقوم بجمع معلومات عن تفاعالتك مع التطبيق لتحسين خدماتنا. يشمل ذلك بيانات حول استخدام التطبيق،
                        ونظام تشغيل الجهاز، ولغة المتصفح.
                    </li>
                </ul>
            </div>

            <!-- 2 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">حماية خصوصيتك</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الالتزام بالخصوصية :
                        </span>
                        نحن نقدر ثقتك وملتزمون بحماية خصوصيتك. ال نقوم ببيع أو تأجير أو مشاركة معلوماتك الشخصية
                        مع أطراف ثالثة باستثناء ما هو مذكور في هذه السياسة.
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            مزودو الخدمة :
                        </span>
                        قد نقوم بتوظيف شركات أو أفراد آخرين ألداء مهام نيابة عنا، مثل معالجة الطلبات، وتوفير الصيانة،
                        وإرسال
                        الرسائل، وتحليل البيانات. قد يكون لهؤالء األطراف حق الوصول إلى معلوماتك، ولكنها ستستخدم فقط
                        ألداء المهام المحددة .
                    </li>
                </ul>
            </div>

            <!-- 3 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">استخدام المعلومات</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            غرض البيانات:
                        </span>
                        تُستخدم معلوماتك الشخصية فقط لتقديم وتعزيز خدماتنا، بما في ذلك معالجة الطلبات، وتحليل أنماط
                        االستخدام، وتقديم الدعم المخصص .
                    </li>
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الافصاح:
                        </span>
                        قد نكشف عن معلوماتك إذا كان ذلك مطلوبًا بموجب القانون، أو لالمتثال لإلجراءات القانونية، أو
                        لحماية حقوق
                        وسالمة اآلخرين.
                    </li>
                </ul>
            </div>

            <!-- 4 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">أمان البيانات</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            القيود:
                        </span>
                        على الرغم من أننا نسعى لحماية بياناتك الشخصية، ال يمكن ضمان أمان النقل عبر اإلنترنت بالكامل. نحن
                        ال نتحمل
                        المسؤولية عن أي خروقات تتجاوز سيطرتنا.
                    </li>
                </ul>
            </div>

            <!-- 5 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">تغييرات السياسة</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            التحديثات:
                        </span>
                        قد نقوم بتحديث سياسة الخصوصية هذه من وقت آلخر. سيتم نشر أي تغييرات على التطبيق وستصبح سارية
                        المفعول فور نشرها.
                    </li>
                </ul>
            </div>

            <!-- 6 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">التواصل</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            انواع التواصل:
                        </span>
                        باستخدام تطبيقنا، فإنك توافق على تلقي التواصل منا عبر البريد اإللكتروني، واإلشعارات، وقنوات أخرى
                        حسب الحاجة. قد تشمل هذه الرسائل تحديثات الخدمة، والترويج، والمعلومات األخرى ذات الصلة.
                    </li>
                </ul>
            </div>

            <!-- 7 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">حقوقك</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الوصول والتحكم:
                        </span>
                        لديك الحق في الوصول إلى معلوماتك الشخصية وتحديثها. يمكنك أي ًضا طلب حذف بياناتك، على الرغم من
                        أن بعض المعلومات قد تُحتفظ ألغراض قانونية.
                    </li>
                </ul>
            </div>

            <!-- 8 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">أمان الحساب</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            المسؤولية:
                        </span>
                        أنت مسؤول عن الحفاظ على سرية معلومات حسابك وكلمة المرور الخاصة بك. قم باإلبالغ عن أي استخدام غير
                        مصرح به لحسابك على الفور. نحن غير مسؤولين عن أي خسائر ناتجة عن الوصول غير المصرح به.
                    </li>
                </ul>
            </div>

            <!-- 9 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">معلومات الاتصال</p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الاستفسارات:
                        </span>
                        لأي أسئلة أو استفسارات تتعلق بسياسة الخصوصية هذه، يرجى االتصال بنا على:
                        <ul class="list-disc ps-6">
                            <li class="my-2">
                                <span class="text-base font-bold">
                                    البريد الالكتروني:
                                </span>
                                info&#64;squarement.sa
                            </li>
                            <li class="my-2">
                                <span class="text-base font-bold">
                                    الهاتف:
                                </span>
                                <!-- +966 509 152 104 -->
                                104 152 509 966+
                            </li>
                            <li class="my-2">
                                <span class="text-base font-bold">
                                    العنوان:
                                </span>
                                جدة، حي الرحاب، مركز األقصى لألعمال، المملكة العربية السعودية
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <!-- 10 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">الملكية والحقوق </p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الملكية الفكرية:
                        </span>
                        جميع العالمات التجارية والشعارات والتصاميم المعروضة على تطبيق ليزر هي ملك لشركة ليزر أو لمالكيها
                        المعنيين. استخدام هذه العالمات التجارية بدون إذن محظور.
                    </li>
                </ul>
            </div>

            <!-- 11 -->
            <div class="my-8">
                <p class="text-lg font-bold my-1">الشروط العامة </p>
                <ul class="list-disc">
                    <li class="my-2">
                        <span class="text-base font-bold">
                            الامتثال القانوني:
                        </span>
                        باستخدام تطبيقنا، فإنك توافق على االمتثال لجميع القوانين واللوائح المعمول بها.
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="w-3/4 mx-auto my-8">
        <hr>
    </div>

    <div class="container mx-auto pt-8 pb-4 px-8" style="direction: ltr;">
        <!-- english-section -->
        <h2 class="text-2xl font-bold">Privacy Policy</h2>
        <p class="text-lg font-bold my-1">Introduction </p>
        <article>
            Welcome to Laser App. This Privacy Policy explains how we collect, use, and protect your personal data
            when you use our application. By using Laser App, you consent to the collection and use of your
            information as described in this policy.
        </article>

        <!-- 1 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Data Collection and Use</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Personal Data Collection:
                    </span>
                    We collect personal information you provide when you register or
                    make requests through the app. This may include your name, phone number, location, and
                    device details.
                </li>
                <li class="my-2">
                    <span class="text-base font-bold">
                        Usage Data:
                    </span>
                    We collect information about your interactions with the app to improve our
                    services. This includes data on app usage, device operating system, and browser language
                </li>
            </ul>
        </div>

        <!-- 2 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Protection of Your Privacy </p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Commitment to Privacy:
                    </span>
                    We value your trust and are committed to protecting your privacy. We
                    do not sell, rent, or share your personal information with third parties except as outlined in this
                    policy.

                </li>
                <li class="my-2">
                    <span class="text-base font-bold">
                        Service Providers:
                    </span>
                    We may employ third-party companies to perform functions on our behalf,
                    such as processing orders, providing maintenance, and sending communications. These parties
                    may have access to your information but are obligated to use it only for the specific tasks
                    assigned.
                </li>
            </ul>
        </div>

        <!-- 3 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Use of Information</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Purpose of Data:
                    </span>
                    Your personal information is used solely to provide and enhance our services.
                    This includes processing requests, analyzing usage patterns, and offering personalized support.
                </li>
                <li class="my-2">
                    <span class="text-base font-bold">
                        • Disclosure:
                    </span>
                    We may disclose your information if required by law, to comply with legal processes,
                    or to protect the rights and safety of others.
                </li>
            </ul>
        </div>

        <!-- 4 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Data Security</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Limitations:
                    </span>
                    While we strive to protect your personal data, no online transmission can be
                    completely secure. We do not accept responsibility for any breaches beyond our control.
                </li>
            </ul>
        </div>

        <!-- 5 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Policy Changes</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Updates:
                    </span>
                    We may update this Privacy Policy from time to time. Any changes will be posted on
                    the app and will become effective immediately upon posting.
                </li>
            </ul>
        </div>

        <!-- 6 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Communication</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Types of Communication:
                    </span>
                    By using our app, you agree to receive communications from us via
                    email, notifications, and other channels as necessary. These communications may include
                    service updates, promotions, and other relevant information.
                </li>
            </ul>
        </div>

        <!-- 7 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Your Rights</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Access and Control:
                    </span>
                    You have the right to access and update your personal information. You
                    may also request the deletion of your data, although some information may be retained for
                    legal purposes.
                </li>
            </ul>
        </div>

        <!-- 8 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Account Security</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Responsibility:
                    </span>
                    You are responsible for maintaining the confidentiality of your account
                    information and password. Report any unauthorized use of your account to us immediately. We
                    are not liable for any losses resulting from unauthorized access.
                </li>
            </ul>
        </div>

        <!-- 9 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Contact Information </p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Inquiries:
                    </span>
                    For any questions or concerns regarding this Privacy Policy, please contact us at:
                    <ul class="list-disc ps-6">
                        <li class="my-2">
                            <span class="text-base font-bold">
                                Email:
                            </span>
                            info&#64;squarement.sa
                        </li>
                        <li class="my-2">
                            <span class="text-base font-bold">
                                Phone:
                            </span>
                            +966 509 152 104
                            <!-- 104 152 509 966+ -->
                        </li>
                        <li class="my-2">
                            <span class="text-base font-bold">
                                Office Address::
                            </span>
                            Jeddah, Al Rehab, Al Aqsa Business Center, Saudi Arabia
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <!-- 10 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">Ownership and Rights</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Intellectual Property :
                    </span>
                    All trademarks, logos, and designs on Laser App are owned by Laser or
                    their respective owners. Unauthorized use is prohibited.
                </li>
            </ul>
        </div>

        <!-- 11 -->
        <div class="my-8">
            <p class="text-lg font-bold my-1">General Terms</p>
            <ul class="list-disc">
                <li class="my-2">
                    <span class="text-base font-bold">
                        Legal Compliance :
                    </span>
                    By using our app, you agree to comply with all applicable laws and
                    regulations.
                </li>
            </ul>
        </div>

    </div>
</section>