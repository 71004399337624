import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { authenticationInterceptor } from './shared/interceptors/authentication/authentication.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([authenticationInterceptor]),
      withFetch(),
    ),
    provideAnimations(),
    provideToastr({
      positionClass: 'toast-top-center',
      timeOut: 4000,
      disableTimeOut: false,
      closeButton: true,
      progressBar: true,
    }),
  ]
};
