<!-- footer-section-1 -->
<section class="footer-section-1">
    <div class="grid grid-cols-2 md:grid-cols-6 bg-[#1B1D29] py-4">
        <div class="col-span-2 hidden md:flex justify-center items-center">
            <a routerLink="/" class="text-base text-white font-[600] mx-3">الرئيسية</a>
            <a routerLink="/contact-us" class="text-base text-white font-[600] mx-3">تـواصـل مـعنـا</a>
        </div>
        <div class="col-span-2">
            <div class="flex justify-center items-center">
                <a routerLink="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="../../../../assets/lazerLogo.png" class="w-20 h-20" alt="Laser logo in arabic" />
                </a>
            </div>
            <div class="flex md:hidden flex-col justify-center items-center">
                <a routerLink="/" class="text-base text-white font-[600] my-2">الرئيسية</a>
                <a routerLink="/contact-us" class="text-base text-white font-[600] my-2">تـواصـل مـعنـا</a>

                <a routerLink="/privacy-policy" class="text-base text-white font-[600] my-2">سياسة الخصوصية</a>
                <a routerLink="/terms-conditions" class="text-base text-white font-[600] my-2">الشروط والاحكام</a>
            </div>
        </div>
        <div class="col-span-2 hidden md:flex justify-center items-center">
            <a routerLink="/terms-conditions" class="text-base text-white font-[600] mx-3">الشروط والاحكام</a>
            <a routerLink="/privacy-policy" class="text-base text-white font-[600] mx-3">سياسة الخصوصية</a>
        </div>
    </div>
</section>
