import { Routes } from '@angular/router';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { TermsComponent } from './shared/components/terms/terms.component';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'order-wizard',
        loadChildren: () => import('./pages/laser-form/laser-form.module').then(m => m.LaserFormModule),
    },
    // {
    //     path: '',
    //     loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    // },
    // {
    //     path: 'user-authentication',
    //     loadChildren: () => import('./pages/user-auth/user-auth.module').then(m => m.UserAuthModule),
    // },
    // {
    //     path: 'book-service',
    //     loadChildren: () => import('./pages/book-service/book-service.module').then(m => m.BookServiceModule),
    // },
    // {
    //     path: 'payment',
    //     loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
    // },
    /* *** *** *** *** *** */
    {
        path: 'privacy-policy',
        component: PrivacyComponent,
        title: 'Laser - Privacy Policy',
    },
    {
        path: 'terms-conditions',
        component: TermsComponent,
        title: 'Laser - Terms and Conditions',
    },

    { path: '**', redirectTo: 'order-wizard', pathMatch: 'full' },

];
