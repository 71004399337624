import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivateData, LoginData, RegisterData } from './auth.interface';
import { _ApiCustomerBaseURL } from '../../model/variables.model';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {


  private isLogged = new BehaviorSubject<boolean>(false);
  authenticated = this.isLogged.asObservable();

  constructor(private _HttpClient: HttpClient) { }

  register(userData: RegisterData): Observable<any> {
    return this._HttpClient.post(`${_ApiCustomerBaseURL}/register`, userData)
  }

  activate(userData: ActivateData): Observable<any> {
    return this._HttpClient.post(`${_ApiCustomerBaseURL}/activate`, userData)
  }

  forgotPassword(userData: any): Observable<any> {
    return this._HttpClient.post(`${_ApiCustomerBaseURL}/forgot-password`, userData)
  }

  codeConfimation(confirmationData: any): Observable<any> {
    return this._HttpClient.post(`${_ApiCustomerBaseURL}/code-confirmation`, confirmationData)
  }

  resetPassword(userData: any, reset_token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${reset_token}`,
    });

    return this._HttpClient.post(`${_ApiCustomerBaseURL}/reset-password`, userData, { headers })
  }

  login(userData: LoginData): Observable<any> {
    return this._HttpClient.post(`${_ApiCustomerBaseURL}/login`, userData)
  }

  logout(): Observable<any> {
    return this._HttpClient.get(`${_ApiCustomerBaseURL}/logout`)
  }

  updateAuthFn(authStatus: boolean): void {
    this.isLogged.next(authStatus);
  }

  tokenValidator(uBody: any): Observable<any> {
    return this._HttpClient.post(`${_ApiCustomerBaseURL}/token-validator`, uBody)
  }

}
